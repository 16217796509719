import { useEffect, useState } from "react";
import {
  Audio,
  Icon,
  Image,
  Markdown,
  Select,
} from "../../../atoms/components";
import {
  AnswerRequiredObject,
  ExamItemV2,
} from "../../../pages/components/AssessmentViewerV2";

export interface MultipleChoiceItemProps {
  item?: ExamItemV2;
  formData?: (data: any) => void;
  inputDisabled?: boolean;
  updateRequiredValue?: (requiredStateEntry: AnswerRequiredObject) => void;
}

export function MultipleChoiceItem({
  item,
  formData = () => {},
  inputDisabled,
  updateRequiredValue = (requiredStateEntry: {
    isAnswered: boolean;
    itemUuid: string;
  }) => {},
}: MultipleChoiceItemProps) {
  const [selected, setSelected] = useState(
    item?.subtype === null || (item?.subtype === "" && item?.choices)
      ? item?.draftValue?.choices?.[0]?.uuid ?? null
      : item?.draftValue?.choices?.[0]?.uuid ?? item?.choices?.[0]?.uuid ?? null
  );

  useEffect(() => {
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: selected ? [selected] : [],
    }));
  }, []);

  useEffect(() => {
    if (item?.required) {
      updateRequiredValue({
        isAnswered: selected !== null,
        itemUuid: item.uuid || "",
      });
    }
  }, [selected]);

  const onValueSelect = (event: any) => {
    setSelected(event.target.value);
    formData((values: any) => ({
      ...values,
      [item?.uuid || ""]: [event.target.value],
    }));
  };

  return (
    <div className="mb-16 ">
      <div className="text-lg font-bold">
        <span className="text-error-500">{item?.required ? "* " : ""}</span>
        <Markdown text={item?.text || ""} />
      </div>
      <div className={`py-4 ${item?.mediaUrl ? "" : "hidden"}`}>
        <Image
          src={item?.mediaUrl || ""}
          alt={item?.mediaUrl || ""}
          className={`w-full min-h-40 max-h-[30rem] object-contain bg-opacity-0`}
        />
      </div>

      {/* Dropdown : Multiple Choice */}
      {item?.subtype !== null && item?.subtype !== "" ? (
        <div>
          <Select
            name="question"
            id="question"
            onChange={onValueSelect}
            className="w-full md:w-1/2 lg:w-1/3"
            value={selected || item?.choices?.[0]?.uuid || ""}
            disabled={inputDisabled}
          >
            {item?.choices?.map((choice, index: number) => {
              return (
                <option
                  key={choice?.uuid}
                  value={choice?.uuid || ""}
                  id={choice?.uuid || ""}
                >
                  {choice?.longInput}
                </option>
              );
            })}
          </Select>
        </div>
      ) : (
        <div>
          {item?.choices?.map((choice) => {
            // Para no need to question mark per choice haha mas malinis
            if (choice)
              return (
                <div key={choice.uuid} className="my-8">
                  <input
                    className=" focus:border-primary-300 focus:ring-primary-300 text-primary-600"
                    disabled={inputDisabled}
                    type="radio"
                    checked={selected === choice.uuid}
                    onChange={onValueSelect}
                    value={choice.uuid || ""}
                    id={choice.uuid || ""}
                  />
                  <label htmlFor={choice.uuid || ""} className="ml-2">
                    {choice.longInput}
                  </label>
                  <Image
                    src={choice.imageUrl || choice.mediaUrl || ""}
                    alt={choice.imageUrl || choice.mediaUrl || ""}
                    className={`w-full object-contain min-h-24 max-h-[30rem] bg-opacity-0 mt-4 ${
                      choice.imageUrl || choice.mediaUrl ? "" : "hidden"
                    }`}
                  />
                  <Audio src={choice.audioUrl || ""} className="mt-4" />
                </div>
              );
          })}
        </div>
      )}
    </div>
  );
}
