import React, { useEffect, useContext, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getPdfTemplate } from "./template/pdfTemplate";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { isMobile } from "react-device-detect";

GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;

export interface CasticulatePdfResourceProps {
  prop?: string;
  node?: CasticulateNode;
}

export function CasticulatePdfResource({
  prop = "default value",
  node = {},
}: CasticulatePdfResourceProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });

  // Mobile compatible converted pdf to image state
  const [images, setImages] = useState<string[] | null>(
    slideContext.slideStates.find((state) => state.slideUuid === node.id)
      ?.pdfSlideState?.images || null
  );

  const [progress, setProgress] = useState<number>(0); // Add progress state

  const RESOURCE_TEMPLATE_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      uri: nodeData.uri,
      title: nodeData.title,
      buttonText: "Continue",
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
      isMobile: isMobile,
      images: images,
      progress: progress, // Add progress to the template view
    }),
    [node, nodeData, images, progress]
  );

  const handleImage = (images: string[]) => {
    setImages(images);
  };

  useEffect(() => {
    if (images === null || images.length === 0)
      pdfToImage(nodeData.uri || "", handleImage, setProgress);
  }, [node]);

  useEffect(() => {
    slideContext.appendSlideStates({
      slideUuid: node.id || "",
      pdfSlideState: {
        images: images || [],
      },
    });
    slideContext.handleRerun();
  }, [images]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getPdfTemplate(nodeData.theme)}
        view={RESOURCE_TEMPLATE_VIEW}
      />
      {/* <pre>{JSON.stringify(images.length, null, 4)}</pre>
      <pre>{JSON.stringify(images, null, 4)}</pre> */}
      {/* {images.map((image, index: number) => {
        return <img src={image} key={index} />;
      })} */}
    </div>
  );
}

async function pdfToImage(
  url: string,
  setImages: (images: string[]) => void,
  setProgress: (progress: number) => void // Add setProgress parameter
) {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch PDF file from URL: ${response.statusText}`
      );
    }

    const blob = await response.blob();
    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const arrayBuffer = fileReader.result;
      const pdf = await getDocument(arrayBuffer as any).promise;
      const pagePromises: Promise<string>[] = [];

      for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const scale = 1.5;
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext as any).promise;

        const imageData = canvas.toDataURL("image/jpeg");
        pagePromises.push(Promise.resolve(imageData));

        // Update progress after each page is processed
        setProgress(Math.floor((pageNumber / pdf.numPages) * 100));
      }

      const pageImages = await Promise.all(pagePromises);
      setImages(pageImages);
      setProgress(100); // Ensure progress is set to 100% when done
    };

    fileReader.readAsArrayBuffer(blob);
  } catch (error) {
    console.error("Error in pdfToImage:", error);
  }
}
