import React, { useEffect, useMemo, useContext, useLayoutEffect } from "react";
import { MustacheTemplateRenderer } from "../../../atoms/components";
import { useState } from "react";
import {
  CasticulateNode,
  SlideContext,
} from "../../../pages/components/Casticulate";
import camelcaseKeys from "camelcase-keys";
import { getAssessmentCheckboxTemplate } from "./template/assessmentCheckboxTemplate";
import { marked } from "marked";

export interface CasticulateAssessmentCheckboxProps {
  node?: CasticulateNode;
}

export function CasticulateAssessmentCheckbox({
  node,
}: CasticulateAssessmentCheckboxProps) {
  const slideContext = useContext(SlideContext);
  const nodeData = camelcaseKeys(node?.data, { deep: true });
  const previousRecord = slideContext.slideStates.find(
    (slideState) => slideState.slideUuid === node?.id
  )?.assessmentSlideState;

  const numberOfCorrectAnswers = nodeData.choices.filter(
    (choice: any) => choice.isCorrect === true
  ).length;

  const [selections, setSelections] = useState<string[]>(
    previousRecord?.answers || []
  );

  const [selectionsInText, setSelectionsInText] = useState<string[]>(
    previousRecord?.answersInText || []
  );

  const [correctnessArray, setCorrectnessArray] = useState<
    | {
        id: string;
        isCorrect: boolean;
      }[]
    | []
  >(previousRecord?.correctnessArray || []);
  const [hasAnswered, setHasAnswered] = useState(
    previousRecord?.hasAnswered || null
  );
  const [inputText, setInputText] = useState<string>("");

  const correctAnswerFeedback = nodeData.correctFeedbackMessage || "Correct";
  const wrongAnswerFeedback = nodeData.incorrectFeedbackMessage || "Incorrect";
  const changedAnswer =
    previousRecord?.answers?.some((answer) => !selections.includes(answer)) ||
    previousRecord?.answers?.length !== selections.length;

  const ASSESSMENT_TEMPLATE_CHECKBOX_VIEW = useMemo(
    () => ({
      isGbf: /gbf/g.test(window.location.href),
      question: nodeData.question || "",
      choices: nodeData.choices || [],
      allChoiceHasImage: function () {
        return !nodeData?.choices.some(
          (choice: any) => choice?.imageUri === ""
        );
      },
      questionImage: () => {
        return !hasAnswered && nodeData.imageUri ? nodeData.imageUri : "hidden";
      },
      hasAnswered: hasAnswered,
      showCorrectness: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return selections.includes(id) ? "" : " !hidden ";
        };
      },
      buttonText:
        hasAnswered && !changedAnswer ? "Continue" : "Answer to Continue",
      buttonId: hasAnswered && !changedAnswer ? "nextButton" : "submitButton",
      disabled: selections.length === 0 ? "disabled" : "",
      disabledInput: previousRecord ? "disabled" : "",
      feedbackImage: () => {
        return hasAnswered && !changedAnswer
          ? previousRecord?.isCorrect
            ? nodeData.correctFeedbackImage !== ""
              ? nodeData.correctFeedbackImage
              : "hidden"
            : nodeData.incorrectFeedbackImage !== ""
            ? nodeData.incorrectFeedbackImage
            : "hidden"
          : "hidden";
      },
      progressBar: slideContext.progressBar,
      lastVisitedTitlePage: function () {
        return function (val: any, render: any) {
          const id = render(val);
          const progressBarItemTitle = slideContext.progressBar.find(
            (progressBarItem) =>
              progressBarItem.id === slideContext.lastVisitedTitlePageId
          )?.data.title;
          return id === slideContext.lastVisitedTitlePageId &&
            (progressBarItemTitle ? progressBarItemTitle.trim() !== "" : false)
            ? "pr-4"
            : "hidden";
        };
      },
      lastItemInProgressBar: function () {
        return function (val: any, render: any) {
          const id = render(val);
          return id ===
            slideContext.progressBar[slideContext.progressBar.length - 1].id
            ? "hidden"
            : "";
        };
      },
    }),
    [
      node,
      hasAnswered,
      selections,
      previousRecord,
      slideContext.lastVisitedTitlePageId,
    ]
  );

  function setChoice(this: HTMLElement) {
    const selectedChoiceValue: string = this.getAttribute("value") || "";
    const isCorrect: boolean =
      this.getAttribute("data-is-correct")?.toLowerCase() === "true";

    let updatedSelections: string[] = [];

    if (selections.includes(selectedChoiceValue))
      updatedSelections = selections.filter(
        (selection) => selection !== selectedChoiceValue
      );
    else updatedSelections = [...selections, selectedChoiceValue];
    setSelections(updatedSelections);

    // Update Selection In Text
    let updatedSelectionsInText: string[] = [];
    const selectedChoiceTextValue: string = this.dataset.text || "";
    console.log(selectedChoiceTextValue);
    if (selectionsInText.includes(selectedChoiceTextValue))
      updatedSelectionsInText = selectionsInText.filter(
        (selection) => selection !== selectedChoiceTextValue
      );
    else
      updatedSelectionsInText = [...selectionsInText, selectedChoiceTextValue];
    setSelectionsInText(updatedSelectionsInText);

    console.log(updatedSelectionsInText);

    if (
      correctnessArray?.some(
        (correctnessObject) => correctnessObject.id === selectedChoiceValue
      )
    ) {
      const newCorrectnessArray = correctnessArray.filter(
        (correctnessObject) => correctnessObject.id !== selectedChoiceValue
      );
      setCorrectnessArray(newCorrectnessArray);
    } else {
      setCorrectnessArray((prev) => [
        ...prev,
        { id: selectedChoiceValue, isCorrect: isCorrect },
      ]);
    }
  }

  // Assessment Slide Use Effect
  useLayoutEffect(() => {
    // Get all button under an element with  id="choices"
    const buttons = document.querySelectorAll("#choices button");

    buttons.forEach((button) => {
      button.addEventListener("click", setChoice);

      // Setting Colors for Selected and Not Selected Choices
      const value = button.getAttribute("value") as string;
      // If Button value matches with choice uuid, change color
      if (selections.includes(value)) {
        button.classList.add("bg-white");
        button.classList.remove("text-white");
        button.classList.add("text-black");
      }
      // If Button value did not match with choice uuid, go back to default color
      else {
        button.classList.remove("bg-white");
        button.classList.remove("text-black");
        button.classList.add("text-white");
      }
    });

    slideContext.handleRerun();

    return () => {
      buttons.forEach((button) => {
        button.removeEventListener("click", setChoice);
      });
    };
  }, [selections, node, ASSESSMENT_TEMPLATE_CHECKBOX_VIEW]);

  // Post Submissiong Use Effect
  useLayoutEffect(() => {
    const feedbackTextElement = document.getElementById("feedbackText");
    const feedbackIconElement = document.getElementById("feedbackIcon");
    const questionElement = document.getElementById("question");

    if (feedbackTextElement && feedbackIconElement && questionElement) {
      if (previousRecord?.isCorrect) {
        feedbackIconElement.innerHTML = "new_releases";
        feedbackIconElement.classList.add("mr-2");

        feedbackTextElement.innerHTML = "You got it right!";

        questionElement.innerHTML =
          marked.parse(correctAnswerFeedback) || correctAnswerFeedback;
      }
      if (!previousRecord?.isCorrect) {
        feedbackIconElement.innerHTML = "info";
        feedbackIconElement.classList.add("mr-2");

        feedbackTextElement.innerHTML = "Hmm, not quite";

        questionElement.innerHTML =
          marked.parse(wrongAnswerFeedback) || wrongAnswerFeedback;
      }

      if (hasAnswered === true && changedAnswer) {
        feedbackIconElement.innerHTML = "";
        feedbackIconElement.classList.remove("mr-2");

        feedbackTextElement.innerHTML = nodeData.title;
        questionElement.innerHTML = nodeData.question;
      }

      if (hasAnswered === null) {
        feedbackIconElement.innerHTML = "";
        feedbackIconElement.classList.remove("mr-2");
        feedbackTextElement.innerHTML = nodeData.title;
        questionElement.innerHTML = nodeData.question;
      }
    }
  }, [previousRecord, correctnessArray, ASSESSMENT_TEMPLATE_CHECKBOX_VIEW]);

  const submitFunction = () => {
    setHasAnswered(true);
    slideContext.appendSlideStates({
      slideUuid: node?.id || "",
      assessmentSlideState: {
        activityType: node?.slideType || "",
        answers: selections,
        answersInText: selectionsInText,
        isCorrect:
          !correctnessArray.some(
            (correctnessObject) => correctnessObject.isCorrect === false
          ) && correctnessArray.length === numberOfCorrectAnswers,
        hasAnswered: true,
        correctnessArray: correctnessArray,
        groupId: nodeData.groupId || "",
      },
    });
  };

  useLayoutEffect(() => {
    const required = true;
    const submitButton = document.getElementById("submitButton");
    submitButton?.addEventListener("click", submitFunction);

    return () => {
      submitButton?.removeEventListener("click", submitFunction);
    };
  }, [ASSESSMENT_TEMPLATE_CHECKBOX_VIEW, selections, inputText]);

  return (
    <div className="box-border flex flex-col items-center h-full mx-auto">
      <MustacheTemplateRenderer
        template={getAssessmentCheckboxTemplate(nodeData.theme)}
        view={ASSESSMENT_TEMPLATE_CHECKBOX_VIEW}
      />
    </div>
  );
}
